.Form {
  margin-bottom: 20px;
}
.Form-Reference {
  margin-top: 12px;
}
.Form-Field {
  font-family: lft-etica, sans-serif;
  margin-bottom: 20px;
}
.Form-Label {
  display: block;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: #141414;
  margin-bottom: 0.25rem;
  text-align: left;
}
.Form-Input,
.Form-TextArea {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #141414;
  padding: 0.688rem 1rem;
  border: 1px solid #d5d5d5;
  background-color: #f0f0f0;
  border-radius: 0.313rem;
  width: 100%;
  resize: none;
}
.Form-TextArea {
  height: 12.5rem !important;
}
.Form-Input:focus,
.Form-TextArea:focus {
  outline: none;
  border-color: #868686;
  box-shadow: inset 0px 0px 10px rgba(38, 142, 228, 0.5);
}
.Form-Input::placeholder,
.Form-TextArea::placeholder {
  color: #868686;
}
.Form-InputUrl {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.Form-FileSelect {
  margin-bottom: 0.625rem;
}
.Form-InputFile {
  background-color: #f5f5f5;
  border: 1px solid #868686;
  border-radius: 0.313rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #868686;
  font-weight: 600;
  padding: 0.688rem 1rem;
  cursor: pointer;
}
.Form-FileSubtitle {
  font-size: 0.688rem;
  line-height: 0.813rem;
  color: #868686;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
}
.Form-File {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #141414;
  word-break: break-all;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
}
.Form-ButtonContainer {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.Form-FileError {
  font-size: 0.688rem;
  line-height: 0.813rem;
  color: #e00000;
  margin-bottom: 0.625rem;
}

@media (min-width: 600px) {
  .Form_login {
    width: 28.125rem;
  }
  .Form_generator {
    width: 34.5rem;
    margin: auto;
  }
  .Form-Files {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;
    width: 34.5rem;
  }
  .Form-InputFile {
    width: 16.875rem;
  }
}
@media (min-width: 1200px) {
  .Form_generator,
  .Form-Files {
    width: 56.25rem;
  }
  .Form-InputFile {
    width: 18.25rem;
  }
}
